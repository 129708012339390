import useSWR from "swr";

import { vaspSubscriptionsResponseToSubscriptionInfo } from "./mappers";
import {
  GetBillingCustomerParams,
  GetBillingCustomerQuery,
  GetBillingCustomerResponse,
  PaymentSubscriptionInfoParams,
  VaspSubscriptionsResponse,
} from "./types";

import { fetcherThroughAuthProxy } from "~/endpoints/utils";

export const usePaymentSubscriptionInfo = (params: PaymentSubscriptionInfoParams | null) => {
  const paymentSubscriptionInfoURL = `v1/vasps/${params?.vaspDID}/subscriptions`;

  const shouldFetch = params?.vaspDID ? true : false;

  const { data, error, mutate } = useSWR<VaspSubscriptionsResponse>(
    shouldFetch ? paymentSubscriptionInfoURL : null,
    fetcherThroughAuthProxy,
    {
      refreshInterval: 0,
    },
  );

  const subscriptionInfo = data?.data.length ? vaspSubscriptionsResponseToSubscriptionInfo(data) : undefined;

  return {
    data: subscriptionInfo,
    mutate,
    isLoading: !error && !data,
    error,
  };
};

export const getBillingCustomer = async (did: string, query?: GetBillingCustomerQuery) => {
  const url = `v1/billing-customers/${did}${
    query?.includePortalSession ? `?include_portal_session=${query?.includePortalSession}` : ""
  }`;

  const data = await fetcherThroughAuthProxy(url);

  return data;
};

export const useBillingCustomer = (params: GetBillingCustomerParams, query?: GetBillingCustomerQuery) => {
  const url = `v1/billing-customers/${params.did}${
    query?.includePortalSession ? `?include_portal_session=${query?.includePortalSession}` : ""
  }`;

  const shouldFetch = !!params.did;

  const { data, error, mutate, isLoading } = useSWR<GetBillingCustomerResponse>(
    shouldFetch ? url : null,
    fetcherThroughAuthProxy,
    {
      refreshInterval: 0,
    },
  );

  return {
    data,
    mutate,
    isLoading,
    error,
  };
};
