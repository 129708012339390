export interface PaymentSubscriptionInfoParams {
  vaspDID: string;
  includePortalSession?: boolean;
}

// eslint-disable-next-line no-shadow
export enum SubscriptionType {
  PAYING = "PAYING",
  SUNRISE = "SUNRISE",
}

export type InvoiceInfo = {
  dueDate: number | string;
  date: number | string;
  status: string;
};

// eslint-disable-next-line no-shadow
export enum SubscriptionStatus {
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  TRIALING = "trialing",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
  FUTURE = "future",
  PAUSED = "paused",
  IN_TRIAL = "in_trial",
}

export type PaymentSubscriptionInfoResponse = {
  did: string;
  customerId?: string | null;
  subscriptionId?: string;
  currentBillingPeriodUsage: number;
  subscriptionType: SubscriptionType;
  subscriptionStatus: SubscriptionStatus;
  provider?: PaymentProvider;
  portalSessionUrl?: string;
  invoiceInfo?: {
    invoices: InvoiceInfo[];
    hostedPageUrl: string;
  };
};

type PaymentProvider = "STRIPE" | "CHARGEBEE";
type BillingType = "metered" | "unmetered";
type Invoice = {
  id: string;
  date: string;
  due_date: string;
  status: string;
};
type Frequency = "day" | "week" | "month" | "year";

type Subscription = {
  did: string;
  provider?: PaymentProvider;
  customer_id?: string | null;
  subscription_id?: string;
  subscription_type: SubscriptionType;
  subscription_status: SubscriptionStatus;
  is_active: boolean;
  current_billing_period_usage: number;
  plan?: {
    plan_price_id: string;
    addon_price_id?: string;
  };
  billing_type?: BillingType;
  usage_reporting_period?: {
    start: string;
    end: string;
    frequency: {
      value: number;
      unit: Frequency;
    };
  };
  portal_session_url?: string;
  invoice_info?: {
    hosted_page_url: string;
    invoices?: Invoice[];
  };
};

export type VaspSubscriptionsResponse = {
  data: Subscription[];
};

export type GetBillingCustomerParams = {
  did?: string;
};

export type GetBillingCustomerQuery = {
  includePortalSession?: boolean;
};

export type Entitlement = {
  feature_id?: string;
  feature_unit?: string;
  value?: string;
  name?: string;
  is_enabled: boolean;
};

export type GetBillingCustomerResponse = {
  did: string;
  customer_id: string;
  subscription_id?: string;
  plan_price_id?: string;
  entitlements?: Entitlement[];
  invoices: Invoice[];
  hosted_page_url?: string;
  portal_session_url?: string;
};

export type UserPreferenceCreateRequest = {
  preference: string;
  is_enabled: boolean;
};
