import { usePaymentSubscriptionInfo } from "~/endpoints/payment";
import useCurrentViewerVaspDID from "~/hooks/useCurrentViewerVaspDID";

export default function useCurrentViewerSubscriptionInfo() {
  const vaspDID = useCurrentViewerVaspDID();
  const { data: paymentSubscriptionInfo, isLoading, error } = usePaymentSubscriptionInfo(vaspDID ? { vaspDID } : null);

  return {
    isLoading,
    paymentSubscriptionInfo,
    error,
  };
}
